/* eslint-disable indent */
import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import SEO from "components/SEO"
import Layout from "components/layout"
import ModuleRender from "components/ModuleRender"
import Container from "components/container"
import { H3, P } from "components/headings"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import Link from "components/Link"
import GlossaryBg from "images/illustrations/glossary_bg.svg"


const query = graphql`
	query {
		allContentfulGlossaryPost(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					title
					slug
					category
					content {
						content
					}
					seo {
						keywords
					}
				}
			}
		}
		contentfulPage(slug: { eq: "glossary" }) {
			hideFooterDemo
			seo {
				...SEO
			}
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
			}
		}
	}
`
const ArticlesOverview = styled.div`
	padding: 4rem 0;
`
const Categories = styled.div`
	display: block;
	button {
		width: auto;
	}
`
const CategoryHeading = styled.h4`
	text-transform: capitalize;
	width: 100%;
	font-size: 4.2rem;
	font-weight: bold;
	border-bottom: 1px solid lightgrey;
	line-height: 10rem;
}
`
const Category = styled.ul`
	display: flex;
	flex-direction: column;
	flex: 0 0 100%;
	list-style: none;
	margin-left: 0;
	width: 100%;
	button {
		width: auto;
		color: black !important;
		border: 1px solid black !important;
	}
	a {
		color: black;
		font-size: 1.8rem;
		line-height: 1.5;
		text-decoration: none;
		margin-bottom: 1.4rem;

		&:hover {
			text-decoration: underline;
		}
		&:last-of-type {
			margin-top: auto;
		}
	}
	li {
		border-bottom: 1px solid lightgrey;
		width: 80%;
		margin-top: 2rem;
		padding-bottom: 1rem;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin-left: 0;
		
	}
`
const HeroContainer = styled(Container)`
	margin-top: 60px;
	height: calc(100% - 50px);
	display: flex;
	h3,
	p {
		color: white !important;
	}
	h3 {
		text-transform: capitalize;
	}
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`
const Left = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;

	${mediaBreakpoint(BreakPoints.MD)} {
		margin-top: 0px;
		flex-grow: 1;
		height: 100%;
	}
`
const CategoryHeader = styled.div`
	background: url("${GlossaryBg}") no-repeat center top #340E70;
	color: white;
	padding-top: 70px;
	height: 555px;

	${mediaBreakpoint(BreakPoints.MD)} {
		height: 355px;
	}
`
const categories =  ["a", "b", "c", "d", "e", "f", "g", "i", "k", "l", "m", "n", "o", "p", "r", "s", "t", "u", "w"]



const Glossary = () => {
	const { contentfulPage, allContentfulGlossaryPost } = useStaticQuery(query)
	const glossaryPosts = allContentfulGlossaryPost.edges
	const { sections, seo } = contentfulPage

	return (
		<div>
			<SEO {...seo} />
			<Layout knowledgeCentre headerConfig={{ variant: "transparent-white" }} footerConfig={{ variant: "default", hideDemo:false, hideNewsletter:false}}>
			<CategoryHeader>
					<HeroContainer>
						<Left>
							<H3>Glossary</H3>
							<P>
                            Your one-stop-shop for understanding the world of brand and brand tracking terminology
							</P>
						</Left>
						{/* <div>
							
						</div>
						*/}
					</HeroContainer>
				</CategoryHeader>
				<ArticlesOverview>
					<Container>
						<Categories>
							{categories.map(category => {
								const links = glossaryPosts.filter(({ node }) => node.category === category).slice(0, 4)


								return (
									<>
										<CategoryHeading>{category.replace(/-/g, " ")}</CategoryHeading>
										<Category>
										{links.map(post => (
											<li><Link to={`/glossary/${post.node.slug}`}>{post.node.title}</Link></li>
										))}
										</Category>
									</>
								)
							})}
						</Categories>
					</Container>
				</ArticlesOverview>
				<ModuleRender sections={sections} />
			</Layout>
		</div>
	)
}


export default memo(Glossary)